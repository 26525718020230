import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const buttonStyles = {};

const buttonDisabledStyles = {
  opacity: '0.5',
  cursor: 'not-allowed',
};

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

const CheckoutSOCE99 = () => {
  const [loading, setLoading] = useState(false);

  const redirectToCheckout = async (event) => {
    event.preventDefault();
    setLoading(true);

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [{ price: 'price_1I1gjtHszIOAj4aFVRZQcEwn', quantity: 1 }],
      successUrl: `https://stephengerbermusic.com//thank-you/`,
      cancelUrl: `https://stephengerbermusic.com/canceled`,
      billingAddressCollection: 'required',
      shippingAddressCollection: {
        allowedCountries: ['US'],
      },
    });

    if (error) {
      console.warn('Error:', error);
      setLoading(false);
    }
  };

  return (
    <div className="btn-frame">
      <button
        disabled={loading}
        aria-busy={loading}
        style={
          loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
        }
        onClick={redirectToCheckout}
      >
        Buy{loading && 'ing'} Now
      </button>
    </div>
  );
};

export default CheckoutSOCE99;
